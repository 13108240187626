<template>
  <div class="top-container">
    <v-card elevation="1">
      <div>
        <banner></banner>

        <v-row class="avatar-row mb-0">
          <v-col xs="12" md="3" cols="12" class="text-center avatar-col">
            <avatar></avatar>
          </v-col>
          <v-col xs="12" cols="12" md="5" class="diary-top-content">
            <h1 class="headline mt-md-2">{{ diary.title | capitalize }}</h1>
          </v-col>
          <v-col
            xs="12"
            cols="12"
            md="4"
            class="diary-top-content text-right follow"
          >
            <div class="mr-md-5 mt-md-2">
              <template v-if="user && !is_admin">
                <v-btn
                  :loading="is_following_updating"
                  v-if="!is_following"
                  @click="follow"
                  depressed
                  class="text-none"
                >
                  <v-icon left>$vuetify.icons.heartOutline</v-icon>
                  {{ $t("Follow") }}
                </v-btn>

                <v-menu
                  :disabled="is_following_updating"
                  v-if="is_following"
                  offset-y
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :loading="is_following_updating"
                      depressed
                      color="#eaf3ff"
                      class="text-none"
                      v-on="on"
                    >
                      <v-icon left>$vuetify.icons.heart</v-icon>
                      {{ $t("Following") }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="unfollow">
                      <v-list-item-title>{{
                        $t("Unfollow")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn depressed class="text-none ml-2" v-on="on">
                      <v-icon>$vuetify.icons.dotsHorizontal</v-icon>
                    </v-btn>
                  </template>

                  <v-card max-width="250">
                    <v-list v-if="owner">
                      <v-list-item :to="'/user/' + owner.username">
                        <v-list-item-avatar>
                          <user-avatar :user="owner" :size="40"></user-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title class="global-list-header">{{
                            owner.username
                          }}</v-list-item-title>
                          <v-list-item-subtitle class="global-list-desc">{{
                            $t("Owner")
                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list>
                      <v-list-item @click="openWindow(getShare())">
                        <v-list-item-action>
                          <v-icon>$vuetify.icons.fasShareAlt</v-icon>
                        </v-list-item-action>
                        <v-list-item-title class="ml-n5">{{
                          $t("ShareOnFacebook")
                        }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </template>

              <template v-if="is_admin">
                <v-btn
                  :to="{ name: 'diary_admin_members' }"
                  depressed
                  class="mr-2 text-none"
                >
                  <v-icon left>$vuetify.icons.heart</v-icon>
                  {{ $t("FamilyFriends") }}</v-btn
                >
                <button-menu
                  depressed
                  :items="menu"
                  btn-icon="$vuetify.icons.dotsHorizontal"
                ></button-menu>
              </template>
            </div>
          </v-col>
        </v-row>
        <top-menu></top-menu>
      </div>
    </v-card>
  </div>
</template>

<i18n>
    {
    "en": {
    "Follow": "Follow",
    "Unfollow": "Unfollow",
    "Following": "Following",
    "EditProfile": "Manage diary",
    "ShareOnFacebook": "Share on Facebook",
    "Owner": "Owner",

    "FamilyFriends": "Family & Friends",
    "Settings": "Diary Settings",
    "SettingsDesc": "Manage diary Settings",
    "Comments": "Manage comments",
    "PrivacySettings": "Privacy settings",
    "MenuSettings": "Menu settings",
    "CommentsDesc": "Manage guestbook, post and photo comments",
    "PrivacySettingsDesc": "Manage who can visit your diary",
    "MenuSettingsDesc": "Manage and customize the menu"
    },
    "sv": {
    "Follow": "Följ",
    "Unfollow": "Avfölj",
    "Following": "Följer",
    "EditProfile": "Hantera dagbok",
    "ShareOnFacebook": "Dela på Facebook",
    "Owner": "Ägare",
    "FamilyFriends": "Familj & Vänner",
    "Settings": "Inställningar",
    "SettingsDesc": "Hantera inställningar",
    "Comments": "Hantera kommentarer",
    "PrivacySettings": "Sekretessinställningar",
    "MenuSettings": "Menyinställningar",
    "CommentsDesc": "Hantera kommentarer i gästboken, på bilder och inlägg",
    "PrivacySettingsDesc": "Hantera vem som kan besöka din dagbok",
    "MenuSettingsDesc": "Hantera och anpassa menyn"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";
import Banner from "./Banner.vue";
import Avatar from "./Avatar.vue";
import Menu from "./Menu.vue";
import UserAvatar from "@/components/avatars/UserAvatar.vue";

export default {
  name: "DiaryTop",

  data: () => ({
    is_following_updating: false,
    menu: [],
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
      is_following: (state) => state.diary.is_following,
    }),
    owner: function () {
      if (this.diary && this.diary.admins && this.diary.admins.length > 0) {
        return this.diary.admins[0];
      } else return null;
    },
  },
  components: {
    banner: Banner,
    avatar: Avatar,
    "top-menu": Menu,
    "user-avatar": UserAvatar,
  },
  created: function () {
    if (!this.is_admin) return;
    this.setMenu();
  },
  methods: {
    ...mapActions({
      diaryFollow: "diary/follow",
      diaryUnfollow: "diary/unfollow",
    }),
    follow() {
      var self = this;

      self.is_following_updating = true;

      self
        .diaryFollow()
        .then(function () {
          self.is_following_updating = false;
        })
        .catch(function (error) {
          self.is_following_updating = false;
          self.$ajaxErrorNoty(error);
        });
    },
    unfollow() {
      var self = this;

      self.is_following_updating = true;

      self
        .diaryUnfollow()
        .then(function () {
          self.is_following_updating = false;
        })
        .catch(function (error) {
          self.is_following_updating = false;
          self.$ajaxErrorNoty(error);
        });
    },
    getShare() {
      var pageUrl = process.env.VUE_APP_HOST + "/" + this.diary.name;

      return (
        "https://www.facebook.com/dialog/share?app_id=" +
        process.env.VUE_APP_FACEBOOK_APP_ID +
        "&display=popup&href=" +
        encodeURIComponent(pageUrl)
      );
    },
    openWindow: function (url) {
      var windowFeatures =
        "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=300";
      window.open(url, "Share", windowFeatures);
    },
    setMenu() {
      this.menu = [
        {
          name: this.$t("Settings"),
          description: this.$t("SettingsDesc"),
          icon: "$vuetify.icons.fasCog",
          to: { name: "diary_admin_settings" },
          exact: true
        },
        {
          name: this.$t("PrivacySettings"),
          description: this.$t("PrivacySettingsDesc"),
          icon: "$vuetify.icons.fasUserLock",
          to: { name: "diary_admin_privacysettings" },
        },
        {
          name: this.$t("MenuSettings"),
          description: this.$t("MenuSettingsDesc"),
          icon: "$vuetify.icons.fasTools",
          to: { name: "diary_admin_menu" },
        },
        {
          name: this.$t("Comments"),
          description: this.$t("CommentsDesc"),
          icon: "$vuetify.icons.fasComments",
          to: {
            name: "diary_admin_comments_status",
            params: { status: "pending" },
          },
        },
      ];
    },
  },
};
</script>

<style scoped>
.sub-header {
  height: auto !important;
  padding-bottom: 4px;
  font-weight: bold;
}

.avatar-row {
  margin-top: -90px;
}

.diary-top-content {
  margin-top: 95px;
}

.diary-top-content h1 {
  font-weight: bold;
  margin-left: -30px;
}

@media (max-width: 768px) {
  .avatar-row {
    margin: 0 !important;
  }
  .avatar-col {
    margin-top: -90px;
  }
  .diary-top-content {
    margin-top: 0 !important;
  }

  .diary-top-content h1,
  .avatar-row .follow {
    text-align: center !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .avatar-row .follow button {
    margin-bottom: 15px;
  }
}
</style>