<template>
    <v-container class="text-center pt-15">
        <v-icon style="font-size:65px">$vuetify.icons.fasLock</v-icon>
        <h1 class="mt-3 mb-5">{{ $t("RequireAuthentication") }}</h1>
        <p class="mb-10">{{ $t("Description") }}</p>

        <v-btn class="mr-3 text-none" color="primary" :to="'/login?ref=/' + diary.name">{{ $t("Login") }}</v-btn>
        <v-btn :to="'/signup?ref=/' + diary.name" class="text-none">{{ $t("CreateAccount") }}</v-btn>
    </v-container>
</template>

<i18n>
    {
    "en": {
    "RequireAuthentication": "Open for members",
    "Description": "This diary is open to members only. Log in or become a member to read this diary!",
    "Login": "Login",
    "CreateAccount": "Sign up"
    },
    "sv": {
    "RequireAuthentication": "Öppen för medlemmar",
    "Description": "Denna dagbok är enbart öppen för medlemmar. Logga in eller bli medlem för att läsa denna dagbok!",
    "Login": "Logga in",
    "CreateAccount": "Bli medlem"
    }
    }
</i18n>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'DiaryRequireAuthentication',
        computed: {
            ...mapState({
                diary: state => state.diary.profile
            })
        }
    }
</script>