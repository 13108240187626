<template>
  <v-card elevation="1">
    <v-card-title>
      {{ $t("Children") }}
      <v-spacer></v-spacer>

      <button-menu
        icon
        :items="admin_menu"
        btn-icon="$vuetify.icons.dotsHorizontal"
        v-if="is_admin"
      ></button-menu>
    </v-card-title>

    <template v-if="children && children.length > 0">
      <v-list two-line class="pt-0">
        <v-list-item
          v-for="item in children"
          :key="item.id"
          :to="getChildUrl(item.url_name)"
        >
          <v-list-item-avatar :size="50">
            <child-avatar :child="item" :size="50"></child-avatar>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.name | capitalize }}</v-list-item-title>
            <v-list-item-subtitle>{{
              getFormatedChildAge(item.birth)
            }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action v-if="isBirthday(item.birth)">
            <v-icon :title="$t('Birthday')" color="purple accent-1"
              >$vuetify.icons.fasBirthdayCake</v-icon
            >
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </template>

    <v-card-text class="text-center" v-if="!children || children.length == 0">
      {{ $t("NoChildren") }}
      <div class="mt-3" v-if="is_admin">
        <v-btn
          @click="openNewChild"
          text
          small
          color="primary"
          class="text-none"
          >{{ $t("AddChild") }}</v-btn
        >
      </div>
    </v-card-text>

    <child-dialog ref="childDialog" v-if="is_admin"></child-dialog>
    <sort-dialog ref="sortDialog" v-if="is_admin"></sort-dialog>
  </v-card>
</template>

<i18n>
    {
    "en": {
    "Children": "Children",
    "AddChild": "Add child",
    "NoChildren": "There is no children to show!",
    "Birthday": "Birthday",
    "SortChildren": "Change order on children"
    },
    "sv": {
    "Children": "Barn",
    "AddChild": "Lägg till barn",
    "NoChildren": "Det finns inga barn att visa!",
    "Birthday": "Födelsedag",
    "SortChildren": "Ändra ordning på barnen"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
const ChildDialog = () => import("@/components/diary/dialogs/ChildFormDialog.vue");
const ChildrenSortDialog = () => import("@/components/diary/dialogs/ChildrenSortDialog.vue");
import ChildAvatar from "@/components/avatars/ChildAvatar.vue";
import * as moment from "moment";
import { dateHelpers } from "@/_helpers";

export default {
  name: "WidgetChildren",
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
      children: (state) => state.diary.children.list,
    }),
    admin_menu: function () {
      var data = [];

      data.push({
        name: this.$t("AddChild"),
        icon: "$vuetify.icons.accountPlusOutline",
        click: this.openNewChild,
      });

      if (this.children && this.children.length > 1)
        data.push({
          name: this.$t("SortChildren"),
          icon: "$vuetify.icons.sort",
          click: this.openSortChildren,
        });

      return data;
    },
  },
  components: {
    "child-dialog": ChildDialog,
    "sort-dialog": ChildrenSortDialog,
    "child-avatar": ChildAvatar,
  },
  methods: {
    getFormatedChildAge: dateHelpers.getFormatedChildAge,
    getChildUrl(urlName) {
      return "/" + this.diary.name + "/child/" + urlName;
    },
    isBirthday(date) {
      return moment(date).isSame(moment(), "day");
    },
    openNewChild() {
      this.$refs.childDialog.open(this.diary.name);
    },
    openSortChildren() {
      this.$refs.sortDialog.open();
    },
  },
};
</script>

<style scoped>
</style>