<template>
  <v-card elevation="1">
    <v-card-title>{{ $t("Intro") }}</v-card-title>
    <template v-if="diary.about || is_admin">
      <v-card-text>
        <template v-if="!description.is_edit">
          <div v-if="diary.about" class="text-fix text--primary text-center pl-2 pr-2">
            {{ diary.about | capitalize }}
            <div v-if="is_admin" class="mt-3">
              <v-btn
                color="primary"
                text
                small
                @click="editDescription"
                class="text-none"
              >{{ $t("EditBio") }}</v-btn>
            </div>
          </div>
          <div v-if="!diary.about && is_admin" class="text-center">
            {{ $t("DescriptionMissing") }}
            <div class="mt-3">
              <v-btn
                color="primary"
                text
                small
                @click="editDescription"
                class="text-none"
              >{{ $t("AddBio") }}</v-btn>
            </div>
          </div>
        </template>
        <template v-if="description.is_edit && is_admin">
          <v-textarea
            :disabled="description.is_saving"
            :placeholder="$t('BioPlaceholder')"
            counter
            maxlength="100"
            no-resize
            v-model="description.text"
            outlined
            auto-grow
          ></v-textarea>
          <div class="text-right">
            <v-btn
              depressed
              small
              @click="cancelDescription"
              class="mr-1 text-none"
              :disabled="description.is_saving"
            >{{ $t("Button.Cancel") }}</v-btn>
            <v-btn
              depressed
              small
              color="primary"
              class="text-none"
              @click="saveDescription"
              :loading="description.is_saving"
            >{{ $t("Button.Save") }}</v-btn>
          </div>
          <div class="pt-3" v-if="description.error">
            <error-inline :error="description.error"></error-inline>
          </div>
        </template>
      </v-card-text>
    </template>
    <template v-if="!diary.about && !is_admin">
      <v-card-text class="text-center">{{ $t("EmptyBio") }}</v-card-text>
    </template>
  </v-card>
</template>

<i18n>
    {
    "en": {
    "DescriptionMissing": "Add a short bio to tell people more about yourself and your family.",
    "AddBio": "Add bio",
    "EditBio": "Edit bio",
    "BioPlaceholder": "Tell us about you and your family",
    "Intro": "About",
    "SuccessUpdate": "Intro updated!",
    "EmptyBio": "There is no bio written yet!"
    },
    "sv": {
    "DescriptionMissing": "Lägg till en kort bakgrund för att berätta mer om dig och din familj.",
    "AddBio": "Lägg till biografi",
    "EditBio": "Redigera biografi",
    "BioPlaceholder": "Berätta om dig och din familj",
    "Intro": "Om",
    "SuccessUpdate": "Introduktion uppdaterades!",
    "EmptyBio": "Ingen introduktion har skrivits ännu!"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "DiaryProfile",

  data: () => ({
    description: {
      text: "",
      is_edit: false,
      is_saving: false,
      error: null
    }
  }),
  computed: {
    ...mapState({
      diary: state => state.diary.profile,
      is_admin: state => state.diary.is_admin
    })
  },
  methods: {
    ...mapActions({
      updateDescription: "diary/updateDescription"
    }),
    editDescription() {
      this.description.is_edit = true;
      this.description.text = this.diary.about;
    },
    cancelDescription() {
      this.description.is_edit = false;
      this.description.error = null;
    },
    saveDescription() {
      var self = this;
      self.description.error = null;
      self.description.is_saving = true;

      self
        .updateDescription(self.description.text)
        .then(function() {
          self.description.is_saving = false;
          self.description.is_edit = false;
          self.$successNoty(self.$t("SuccessUpdate"));
        })
        .catch(function(error) {
          self.description.is_saving = false;
          self.description.error = error;
        });
    }
  }
};
</script>

<style scoped>
</style>