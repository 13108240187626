<template>
  <v-container class="text-center pt-15">
    <v-icon style="font-size: 65px">$vuetify.icons.fasLock</v-icon>
    <h1 class="mt-3 mb-5">{{ $t("PasswordProtected") }}</h1>
    <p>{{ $t("Description") }}</p>

    <v-form ref="form" v-model="form" lazy-validation>
      <v-row style="max-width: 350px" class="mx-auto">
        <v-col cols="12" xs="12">
          <v-text-field
            :label="$t('EnterPassword')"
            :rules="[(v) => !!v || $t('EnterPassword')]"
            v-model="password"
            :loading="is_sending"
            :disabled="is_sending"
            required
            type="password"
          >
          </v-text-field>
          <v-btn
            color="primary"
            @click="send"
            :loading="is_sending"
            class="text-none"
            >{{ $t("Button.Send") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<i18n>
    {
    "en": {
    "PasswordProtected": "Password protected",
    "Description": "To access the diary, you must enter the diary password.",
    "EnterPassword": "Enter password"
    },
    "sv": {
    "PasswordProtected": "Lösenordsskyddad",
    "Description": "För att få tillgång till dagboken måste du ange dagbokens lösenord.",
    "EnterPassword": "Ange lösenord"
    }
    }
</i18n>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "DiaryPassword",

  data: () => ({
    form: true,
    is_sending: false,
    password: "",
  }),
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      diary: (state) => state.diary.profile,
      children: (state) => state.diary.children,
    }),
  },

  methods: {
    ...mapActions("diary", ["login"]),
    send() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self.is_sending = true;

        self
          .login(self.password)
          .then(function () {})
          .catch(function (error) {
            self.is_sending = false;
            self.password = "";
            self.$ajaxErrorNoty(error);
          });
      }
    },
  },
};
</script>