<template>
    <v-container class="text-center pt-15">
        <v-icon style="font-size:65px">$vuetify.icons.fasUserLock</v-icon>

        <h1 class="mt-3 mb-5">{{ $t("Private") }}</h1>
        <p>{{ $t("Description") }}</p>
    </v-container>
</template>


<i18n>
    {
    "en": {
    "Private": "Private",
    "Description": "This is a private diary. Only the owner has access to read it."
    },
    "sv": {
    "Private": "Privat",
    "Description": "Detta är en privat dagbok. Enbart ägaren har rättighet att läsa den."
    }
    }
</i18n>

<script>
    export default {
        name: 'DiaryPrivate',
    }
</script>