<template>
  <span>
    <v-speed-dial
      v-if="displayType == 'simple'"
      v-model="dialShare"
      :absolute="absolute"
      :bottom="bottom"
      :right="right"
      :direction="direction"
      open-on-hover
    >
      <template v-slot:activator>
        <v-btn
          :fab="fab"
          bottom
          x-small
          :color="color"
          elevation="1"
          :dark="dark"
        >
          <v-icon v-if="dialShare">$vuetify.icons.close</v-icon>
          <v-icon v-else>$vuetify.icons.shareVariant</v-icon>
        </v-btn>
      </template>
      <v-btn
        v-for="item in items"
        :key="item.url"
        dark
        elevation="1"
        fab
        bottom
        :color="item.color"
        x-small
        @click="openWindow(item)"
      >
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>
    </v-speed-dial>

    <span v-if="displayType == 'all'">
      <template v-for="item in items">
        <v-tooltip
          bottom
          :disabled="$isMobile || !item.tooltip || hideTooltip"
          :key="item.url"
        >
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              dark
              v-on="{ ...onTooltip }"
              fab
              elevation="1"
              bottom
              :color="item.color"
              class="ml-1 mr-1"
              x-small
              @click="openWindow(item)"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ item.tooltip }}</span>
        </v-tooltip>
      </template>
    </span>
  </span>
</template>

<i18n>
    {
    "en": {
    "VisitMyDiary": "Visit my diary",
    "ShareOnMessenger": "Share on Messenger",
    "ShareOnFacebook": "Share on Facebook",
    "ShareOnWhatsApp": "Share on WhatsApp"
    },
    "sv": {
    "ShareOn": "Besök min dagbok",
    "ShareOnMessenger": "Dela i Messenger",
    "ShareOnFacebook": "Dela på Facebook",
    "ShareOnWhatsApp": "Dela i WhatsApp"
    }
    }
</i18n>


<script>
// https://www.materialui.co/socialcolors
export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    bottom: {
      type: Boolean,
      default: false,
    },
    right: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#457b9d",
    },
    direction: {
      type: String,
      default: "left",
    },
    fab: {
      type: Boolean,
      default: true,
    },
    dark: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: null,
    },
    displayType: {
      type: String,
      default: "simple",
    },
    hideTooltip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialShare: false,

      items: [],
    };
  },
  created() {
    var share_url = "";

    if (this.url.indexOf("www") > -1) share_url = this.url;
    else {
      var pageUrl = process.env.VUE_APP_HOST;

      if (this.url.startsWith("/")) share_url = pageUrl + this.url;
      else share_url = pageUrl + "/" + this.url;
    }

    var text = this.title;

    if (!text) text = this.$t("VisitMyDiary");

    text = encodeURIComponent(text);

    var fb = {
      url:
        "https://www.facebook.com/dialog/share?app_id=" +
        process.env.VUE_APP_FACEBOOK_APP_ID +
        "&display=popup&href=" +
        encodeURIComponent(share_url),
      icon: "$vuetify.icons.facebook",
      color: "#3b5999",
      network: "facebook",
      tooltip: this.$t("ShareOnFacebook"),
    };

    this.items.push(fb);

    var fb_msg = {
      url:
        "https://www.facebook.com/dialog/send?app_id=" +
        process.env.VUE_APP_FACEBOOK_APP_ID +
        "&display=popup&link=" +
        encodeURIComponent(share_url) +
        "&redirect_uri=" +
        encodeURIComponent(share_url),
      icon: "$vuetify.icons.facebookMessenger",
      color: "#0084ff",
      network: "facebook",
      tooltip: this.$t("ShareOnMessenger"),
    };

    if (this.$isMobile) {
      fb_msg.url =
        "fb-messenger://share?link=" +
        encodeURIComponent(share_url) +
        "&app_id=" +
        encodeURIComponent(process.env.VUE_APP_FACEBOOK_APP_ID);
    }

    this.items.push(fb_msg);

    var wa = {
      url: "https://wa.me/?text=" + text + ".%20" + share_url,
      icon: "$vuetify.icons.whatsapp",
      color: "#25D366",
      network: "wa",
      tooltip: this.$t("ShareOnWhatsApp"),
    };
    this.items.push(wa);
  },
  methods: {
    openWindow: function (item) {
      var networks = {
        facebook: { width: 600, height: 300 },
        twitter: { width: 600, height: 254 },
        google: { width: 515, height: 490 },
        wa: { width: 515, height: 490 },
        linkedin: { width: 600, height: 473 },
      };

      var windowFeatures =
        "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=" +
        networks[item.network].width +
        ",height=" +
        networks[item.network].height +
        "";
      window.open(item.url, "Share", windowFeatures);
    },
  },
};
</script>