<template>
  <div class="diary-toolbar">
    <template v-if="!$isMobile">
      <v-toolbar flat dense class="diary-toolbar-main">
        <v-toolbar-items>
          <template v-for="item in menu_items">
            <v-btn
              active-class="active-menu"
              text
              :exact="item.exact"
              :key="item.key"
              :to="{ name: item.url }"
              class="text-none"
              v-if="item.key != 'children'"
              >{{ item.name }}</v-btn
            >
            <child-menu
              v-if="item.key == 'children'"
              :key="item.key"
            ></child-menu> </template></v-toolbar-items></v-toolbar
    ></template>

    <template v-if="$isMobile">
      <v-tabs class="mobile-tabs-fix" active-class="active-menu" hide-slider>
        <template v-for="item in menu_items">
          <v-tab
            :to="{ name: item.url }"
            :exact-path="item.exact"
            :key="item.key"
            class="text-none"
            >{{ item.name }}</v-tab
          >
        </template>
      </v-tabs>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { menuHelpers } from "@/_helpers";
import ChildrenMenu from "@/components/diary/helpers/MainMenuItems/Children.vue";

export default {
  name: "DiaryMenu",
  components: {
    "child-menu": ChildrenMenu,
  },
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
      user: (state) => state.account.user,
      visitor_info: (state) => state.diary.visitor_info,
      settings: (state) => state.diary.settings
    }),
    menu_items: function () {
      return menuHelpers.getActiveMenu(this.settings, this.visitor_info, this.user);
    },
  },
};
</script>

<style scoped>
.diary-toolbar {
  border-top: solid 1px #e3e3e3 !important;
}
</style>