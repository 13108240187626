<template>
  <div class="avatar-container">
    <blog-avatar
      :diary="diary"
      :size="150"
      v-on:clicked="current_image = 0"
      v-bind:class="{ pointer: diary.avatar }"
    ></blog-avatar>

    <Tinybox
      no-thumbs
      v-if="diary.avatar"
      :images="getAvatars(diary.avatar)"
      v-model="current_image"
    />
    <v-btn
      fab
      v-if="is_admin"
      class="ml-n8"
      small
      bottom
      @click="editAvatar"
      elevation="1"
      style="margin-top: 75px"
    >
      <v-icon small>$vuetify.icons.fasPen</v-icon>
    </v-btn>

    <avatar-dialog
      v-if="is_admin"
      show-album
      ref="avatarDialog"
    ></avatar-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Tinybox from "vue-tinybox";
import BlogAvatar from "@/components/avatars/BlogAvatar.vue";

export default {
  name: "DiaryAvatar",
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  data: () => ({
    is_error: false,
    current_image: null,
  }),
  components: {
    "avatar-dialog": () => import("@/components/dialogs/AvatarDialog.vue"),
    "blog-avatar": BlogAvatar,
    Tinybox,
  },
  methods: {
    getAvatars(image) {
      return [image.original];
    },
    editAvatar() {
      this.$refs.avatarDialog.openDiaryAvatar(this.diary);
    },
  },
};
</script>

<style>
.avatar-container .v-image {
  border: solid 2px rgb(204, 201, 201) !important;
}
</style>