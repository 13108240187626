<template>
  <v-card flat class="banner-container rounded-0">
    <template v-if="diary.banner">
      <v-img
        :src="getBanner(diary.banner)"
        class="grey lighten-2 pointer"
        max-width="1200"
        @click="current_image = 0"
        :height="$bannerHeight"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="black lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <Tinybox
        no-thumbs
        :images="getBanners(diary.banner)"
        v-model="current_image"
      />
    </template>

    <template v-if="!diary.banner">
      <v-sheet :height="$bannerHeight" color="grey lighten-3">
        <v-row
          class="fill-height ma-0 text-center"
          align="center"
          justify="center"
        ></v-row>
      </v-sheet>
    </template>

    <template v-if="is_admin">
      <v-btn elevation="1" right :bottom="!$isMobile" :top="$isMobile" absolute class="text-none" @click="selectFile" :small="$isMobile" depressed>
        <v-icon left class="mr-3">$vuetify.icons.fasImage</v-icon>
        {{ $t("Edit") }}
      </v-btn>
      <banner-dialog show-album show-gallery ref="bannerDialog"></banner-dialog>
    </template>
  </v-card>
</template>

<i18n>
    {
    "en": {
    "ChangeHeader": "Change header",
    "AddACoverPhoto": "Add a cover photo",
    "AddACoverPhotoDesc": "1200 x 300 px",
    "Edit": "Edit cover"
    },
    "sv": {
    "ChangeHeader": "Uppdatera omslagsbild",
    "AddACoverPhoto": "Välj en omslagsbild",
    "AddACoverPhotoDesc": "1200 x 300 px",
    "Edit": "Redigera omslagsbild"
    }
    }
</i18n>

<script>
import { mapState } from "vuex";
import Tinybox from "vue-tinybox";

export default {
  name: "DiaryBanner",

  data: () => ({
    edit_menu: false,
    is_saving: false,
    current_image: null,
  }),
  computed: {
    ...mapState({
      diary: (state) => state.diary.profile,
      is_admin: (state) => state.diary.is_admin,
    }),
  },
  components: {
    "banner-dialog": () => import('@/components/dialogs/BannerDialog.vue'),
    Tinybox
  },
  created() {},
  methods: {
    getBanner(banner) {
      if (!banner) return null;
      return banner.width_1200;
    },
    getBanners(banner) {
      return [banner.original];
    },
    selectFile() {
      this.$refs.bannerDialog.openDiaryBanner(this.diary);
    },
  },
};
</script>